import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  AltPanels,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: 'About Us',
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: PAGES.INBOUND_OP.TITLE,
    absolute_slug: PAGES.INBOUND_OP.PATH,
  },
];

const AboutTripsPage = () => (
  <Layout page="about/inbound-tour-operator-services">

    <Helmet
      title="Inbound Tour Operator Services | New Zealand Tours | Haka Tours"
      meta={[
        {
          name: 'description', content: 'Haka Tours provides inbound tour operator services within New Zealand. Partner with us to get the best New Zealand tour experience for your clients.',
        },
        { name: 'og:title', content: 'Inbound Tour Operator Services | New Zealand Tours | Haka Tours' },
        {
          name: 'og:description', content: 'Haka Tours provides inbound tour operator services within New Zealand. Partner with us to get the best New Zealand tour experience for your clients.',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/inbound-tour-operator-services/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/destinations-bg.jpg?fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">INBOUND TOUR OPERATOR SERVICES</h2>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <div className="l-container l-container--small u-text--center">
        <h1 className="c-heading c-heading--h2">Let us show your customers the very best of Aotearoa/New Zealand</h1>
      </div>
    </section>
    <AltPanels items={[
      {
        content: `<p>Haka Tours has been expertly guiding thousands of guests around New Zealand since 2007.
        We specialise in small-group adventure tours, including snow tours, and our itineraries range from three to twenty-three days in length.
        We also have a private tours division, act as a destination management company for global tourism operators.
        </p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/haka-tours-inbound-image-1.jpg?auto=format',
          align: '',
        },
      },
      {
        content: `<p>When you partner with Haka Tours, you’ll soon see why we regularly receive <a href="https://hakatours.com/about-us/our-awards">accolades and awards</a> not only from the tourism industry,
        but also from the wider business community. These awards reflect our expertise, passion and commitment to delivering outstanding tours,
        striving to exceed your customers’ expectations every step of the way.<br /><br /><a href="http://files.hakatours.com/private-tours-brochure.pdf">Click here to download our Private Tours brochure ></a></p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/haka-tours-inbound-image-3.jpg?auto=format',
          align: '',
        },
      },
    ]}
    />

    <section className="l-section l-section-help">
      <div className="l-container l-container--small u-text--center">
        <h3>If you’re looking for an experienced inbound tour operator to partner with, please email General Manager <a href="mailto:eve@hakatours.com">Eve Lawrence</a> and we’ll be in touch to discuss your needs.</h3>
      </div>
    </section>

    <div className="l-container l-container-logo u-text--center">
      <div className="l-logo-list__grid">
        <div>
          <img alt="" src="https://static-hakatours.imgix.net/logo-qualmark.png?h=100&w=100&auto=format" />
        </div>
        <div>
          <img alt="" src="https://static-hakatours.imgix.net/logo-tia.png?h=200&w=200&auto=format" />
        </div>
        <div>
          <img alt="" src="https://static-hakatours.imgix.net/logo-tourism_export.jpg?h=100&w=100&auto=format" />
        </div>
        <div>
          <img alt="" src="https://static-hakatours.imgix.net/logo-buscoach.gif?h=200&w=200&auto=format" />
        </div>
        <div>
          <img alt="" src="https://static-hakatours.imgix.net/logo-roadtransport.jpg?h=200&w=200&auto=format" />
        </div>
      </div>
    </div>

  </Layout>
);

export default AboutTripsPage;
