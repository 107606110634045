import React from 'react';
import {
  Link, StaticQuery, graphql,
} from 'gatsby';

import { FORMAT_GRAPH_DATA, getImageUrl } from 'utilities';
import { PRIVATE_TOURS_URL } from 'config';


const TourCategories = () => (
  <StaticQuery
    query={graphql`
      query {
        allTourCategories(
          sort: {fields: [order], order: ASC},
          filter: {
                hidden: { eq: false },
              }
        ) {
          edges {
            node {
              id
              name
              absolute_slug
              meta_description
              description_picture_id
              background_color
              crowdriff_code
              crowdriff_header
              inclusions_markdown
              category_icon {
                id
                svg_text
              }
            }
          }
        }

        allTourImages {
          edges {
            node {
              api_id
              imgix_url
            }
          }
        }
      }
    `}
    render={(data) => {
      const tourCategories = FORMAT_GRAPH_DATA(data.allTourCategories);

      return (
        <section className="l-tour-categories">
          <ul className="l-tour-categories__list">
            {tourCategories.map(category => (
              <li
                key={category.id}
                className="l-tour-categories__item"
              >
                <Link
                  to={`${category.absolute_slug}/`}
                  className="l-tour-categories__link"
                  style={{
                    backgroundImage: `url('${getImageUrl({
                      id: category.description_picture_id,
                      images: data.allTourImages.edges,
                      width: 420,
                      height: 620,
                    })}')`,
                  }}
                >
                  <div className="l-tour-categories__link-heading">
                    {category.category_icon && (
                      <div
                        className="l-tour-categories__link-icon"
                        dangerouslySetInnerHTML={{ __html: category.category_icon.svg_text }}
                      />
                    )}
                    <h2 className="c-heading c-heading--h2">
                      {category.name} <br /> Tours
                    </h2>
                  </div>
                  <p>
                    {category.meta_description}
                  </p>
                  <div
                    className="l-tour-categories__link-overlay"
                    style={{ backgroundColor: category.background_color }}
                  />
                </Link>
              </li>
            ))}

            <li className="l-tour-categories__item">
              <a
                href={PRIVATE_TOURS_URL}
                target="_blank"
                className="l-tour-categories__link"
                rel="noopener noreferrer"
              >
                <div className="l-tour-categories__link-heading">
                  <img className="l-tour-categories__link-icon" src="/svg/private.svg" alt="" />
                  <h2 className="c-heading c-heading--h2">Private <br /> Tours</h2>
                </div>
                <p>We’re experts in creating custom, private New Zealand guided holidays for couples, families and friends, plus any other type of groups! Perfect for those who are looking for something private & bespoke.</p>
                <div className="l-tour-categories__link-overlay" />
              </a>
            </li>
          </ul>
        </section>
      );
    }}
  />
);

export default TourCategories;
