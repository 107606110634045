import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { scroller } from 'react-scroll';

import { SCROLL_CONFIG, BASE_URL } from 'config';
import { FORMAT_GRAPH_DATA } from 'utilities';
import { Layout } from 'containers';
import {
  TourCategories,
  TourList,
  VideoIntro,
  TourDescription,
} from 'layouts';
import { Banner, FooterBanner } from 'components';


class ToursPage extends PureComponent {
  /**
   * Render
   */
  render() {
    const { data } = this.props;

    return (
      <Layout page="tours">
        <Helmet
          title="New Zealand Tour Packages | NZ Travel Packages | Haka Tours"
          meta={[
            {
              name: 'description', content: 'View our selection of New Zealand tour packages & find a tour to suit you.Book a New Zealand sightseeing tour today & explore the best of New Zealand with us!',
            },
            { name: 'og:title', content: 'New Zealand Tour Packages | NZ Travel Packages | Haka Tours' },
            {
              name: 'og:description', content: 'View our selection of New Zealand tour packages & find a tour to suit you. Book a New Zealand sightseeing tour today & explore the best of New Zealand with us!',
            },
            { name: 'og:image:secure', content: 'https://static-hakatours.imgix.net/tours-bg.jpg?fit=crop&auto=format' },
            { name: 'og:image', content: 'https://static-hakatours.imgix.net/tours-bg.jpg?fit=crop&auto=format' },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/tours/` }]}
        />

        <Banner backgroundImage="https://static-hakatours.imgix.net/tours-bg.jpg?fit=crop&auto=format">
          <h1 className="c-heading c-heading--h0">Our New Zealand Tour Packages</h1>
          <div className="c-button-container">
            <button
              type="button"
              className="c-button c-button--primary c-button--wide"
              onClick={() => scroller.scrollTo(TourList.name, SCROLL_CONFIG)}
            >
              View all tours
            </button>
          </div>
        </Banner>

        <TourCategories />

        <TourDescription />

        <TourList
          heading={<h2 className="c-heading c-heading--h2">All our New Zealand Tours</h2>}
          list={FORMAT_GRAPH_DATA(data.allTourPages)}
          hideFooter
        />

        <VideoIntro
          title="How our tours work"
          videoUrl="https://www.youtube.com/watch?v=ITSEGjWanWY&feature=youtu.be"
          hideReviews
          disableVideoPromo={false}
        >
          <h4 className="c-heading c-heading--h4">Step 1: SELECT YOUR BASE TOUR</h4>
          <p>
            Every tour includes: accommodation, transport, tour guide, breakfast and a few key activities!
          </p>
          <h4 className="c-heading c-heading--h4">STEP 2: CUSTOMISE YOUR TOUR</h4>
          <p>
            Customise your trip by upgrading your accommodation or adding pre or post tour accommodation.
            You can view the activities available in New Zealand and decide which ones you want to do - just add these to your ‘favourites’ by clicking the heart button.
            All activities can be booked on the road by your Tour Guide.
          </p>
          <h4 className="c-heading c-heading--h4">STEP 3: BOOK & MANAGE YOUR TOUR ONLINE</h4>
          <p>
            Secure your seat for a $99 deposit ($400 for departures from June 2025), then pay it off over time – the balance is due 60 days prior to departure (56 days for departures from June 2025).
          </p>
          <p>
            It’s that easy! If you have any questions at all, then please <a href="/contact-us/" target="_blank">chat with us</a>.
          </p>
        </VideoIntro>

        <FooterBanner />
      </Layout>
    );
  }
}

ToursPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      sort: {fields: [order], order: ASC},
      filter: {
        hidden_category: { eq: false },
        archived: { eq: false }
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          slug
          order
          is_popular
          destination_ids
          destination_groups
          category_ids
          starting_price
          number_of_days
          background_color
          short_description_markdown
          parent_name
          absolute_slug
          picture_id
          small_map_id
          sliding_first_picture_id
          sliding_second_picture_id
          sliding_third_picture_id
          sliding_fourth_picture_id
          sliding_fifth_picture_id
          sliding_sixth_picture_id
          sliding_seventh_picture_id
          sliding_eighth_picture_id
          sliding_ninth_picture_id
          sliding_tenth_picture_id
          category_icons {
            id
            name
            tooltip
            svg_text
          }
          parent_category_icon {
            id
            svg_text
          }
        }
      }
    }
  }
`;

export default ToursPage;
