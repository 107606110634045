import React, { PureComponent } from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { PAGES, CURRENCY, STATES } from 'config';
import { SummaryList, FormInput, withFormGroup } from 'components';
import { GET_BOOKING, UPDATE_BOOKING } from 'actions/booking';
import { numberToCurrency, FORMAT_GRAPH_DATA } from 'utilities/helpers';
import { mapStateToProps } from 'utilities/state';
import { GET_TRAVELLER_COSTS, GET_ACCOMM_COSTS } from './helpers';

const Input = withFormGroup(FormInput);

class BookingSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: '',
      applyingPromo: false,
      promoCodeInserted: false,
      promoCodeErrorMessage: '',
    };
  }

  render() {
    const {
      booking,
      user,
      dispatch,
      showEdit,
      coupons,
    } = this.props;

    const {
      accommodations,
      addOns,
      currentBooking,
      travellers,
      upgradeAccommodations,
    } = booking;

    const {
      session,
    } = user;

    /**
     * Remove an add-on from booking
     * @param travellerID
     * @param addOn
     * @returns {Promise<void>}
     * @constructor
     */
    const handleChange = (e) => {
      this.setState({ promoCode: e.target.value });
    };

    const handleClick = async () => {
      const { promoCode } = this.state;
      this.setState({ applyingPromo: true, promoCodeInserted: true, promoCodeErrorMessage: null });
      const UPDATE = await UPDATE_BOOKING(
        currentBooking.id,
        session.token,
        session.email,
        { promo_code_string: promoCode },
      );
      if (UPDATE && UPDATE?.data?.success) {
        await GET_BOOKING(dispatch, currentBooking.id, session.token, session.email);
      } else {
        const message = 'Sorry! This Code is invalid'
        this.setState({ promoCodeErrorMessage: message })
      }
      dispatch({
        type: 'BOOKINGS_SET_UPDATING',
        payload: false,
      });
      this.setState({ applyingPromo: false });
    };

    const REMOVE_ADD_ON = async (travellerID, addOn) => {
      const UPDATED_BOOKING = {
        travellers,
      };
      const TRAVELLER_INDEX = UPDATED_BOOKING.travellers.findIndex(trav => trav.id === travellerID);

      if (TRAVELLER_INDEX === -1) return;

      UPDATED_BOOKING.travellers[TRAVELLER_INDEX].addons = [{
        ...addOn,
        _destroy: true,
      }];

      dispatch({
        type: 'BOOKINGS_SET_UPDATING',
        payload: true,
      });

      const UPDATE = await UPDATE_BOOKING(
        currentBooking.id,
        session.token,
        session.email,
        UPDATED_BOOKING,
      );
      if (UPDATE && UPDATE.success) {
        await GET_BOOKING(dispatch, currentBooking.id, session.token, session.email);
      } else {
        dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: `failed-to-remove-add-on-${addOn.id}`,
            type: 'error',
            content: 'Failed to remove add-on activity',
          },
        });
      }
      dispatch({
        type: 'BOOKINGS_SET_UPDATING',
        payload: false,
      });
    };

    const BEFORE_ACCOM = accommodations.filter(accom => accom.is_before);
    const BEFORE_COST = GET_ACCOMM_COSTS(
      BEFORE_ACCOM,
      'before',
      currentBooking.id,
      session,
      dispatch,
    );
    const AFTER_ACCOM = accommodations.filter(accom => !accom.is_before);
    const AFTER_COST = GET_ACCOMM_COSTS(
      AFTER_ACCOM,
      'after',
      currentBooking.id,
      session,
      dispatch,
    );

    let COSTS = [
      {
        id: 1,
        title: `${currentBooking.traveller_ids.length} x ${currentBooking.tour_name}`,
        content: `${numberToCurrency(currentBooking.tour_price * currentBooking.traveller_ids.length)}`,
      },
    ];

    // Accommodation Upgrades
    if (Array.isArray(upgradeAccommodations) && upgradeAccommodations.length) {
      const UPGRADE_PRICE = upgradeAccommodations.map(item => item.price).reduce((accumulator, currentValue) => currentValue + accumulator);
      COSTS.push({
        id: 2,
        title: 'Accommodation upgrade',
        content: `${numberToCurrency(UPGRADE_PRICE)}`,
        removeBtnCallback: async () => {
          dispatch({
            type: 'BOOKINGS_SET_UPDATING',
            payload: true,
          });

          const REMOVE = await UPDATE_BOOKING(currentBooking.id, session.token, session.email, {
            upgrade_accommodations: upgradeAccommodations.map(item => ({ id: item.id, _destroy: true })),
          });
          if (REMOVE && REMOVE.success) {
            // Get updated booking data
            GET_BOOKING(dispatch, currentBooking.id, session.token, session.email);
          } else {
            dispatch({
              type: 'MESSAGES_ADD',
              payload: {
                id: 'booking-summary-failed-remove-accomm',
                type: 'error',
                content: 'Failed to remove accommodation upgrade.',
              },
            });
          }
          dispatch({
            type: 'BOOKINGS_SET_UPDATING',
            payload: false,
          });
        },
      });
    }

    const KEY_DATES = [
      {
        id: 2,
        title: 'Tour starts',
        content: moment(currentBooking.departure_date).format('D MMM YYYY'),
      },
      {
        id: 3,
        title: 'Tour ends',
        content: moment(currentBooking.end_date).format('D MMM YYYY'),
      },
    ];

    if (BEFORE_COST) COSTS.push(BEFORE_COST);
    if (AFTER_COST) COSTS.push(AFTER_COST);

    const ACCOMM_START_DATE = moment(currentBooking.departure_date);
    if (BEFORE_ACCOM && BEFORE_ACCOM.length) {
      // how to deal with more than one "before" accomm option in terms of number of nights? Possible for a booking?
      ACCOMM_START_DATE.subtract(BEFORE_ACCOM[0].nights, 'days');
    }

    const ACCOMM_END_DATE = moment(currentBooking.end_date);
    if (AFTER_ACCOM && AFTER_ACCOM.length) {
      // how to deal with more than one "after" accomm option in terms of number of nights? Possible for a booking?
      ACCOMM_END_DATE.add(AFTER_ACCOM[0].nights, 'days');
    }

    const COUPON_APPLIED = coupons.coupons.find(cp => cp.code === currentBooking.promo_code_string);
    const HIDE_REMOVE_BTNS = currentBooking && currentBooking.locked;
    let CHECKOUT_OF_ACCOMM = ACCOMM_END_DATE;
    if (currentBooking.links.has_extra_night) {
      CHECKOUT_OF_ACCOMM = ACCOMM_END_DATE.add(1, 'days');
    }

    if (currentBooking.agree_with_the_conditions) {
      KEY_DATES.push({
        id: 1,
        title: 'Check-in to accommodation',
        content: ACCOMM_START_DATE.format('D MMM YYYY'),
      });
      KEY_DATES.push({
        id: 4,
        title: 'Check-out of accommodation',
        content: CHECKOUT_OF_ACCOMM.format('D MMM YYYY'),
      });
    }

    if (travellers.length === 1) {
      const travCosts = GET_TRAVELLER_COSTS(travellers[0], addOns, REMOVE_ADD_ON);
      COSTS = COSTS.concat(travCosts);
    }

    return (
      <StaticQuery
        query={graphql`
           query {
            allTourPages(
              sort: {fields: [order], order: ASC},
              filter: {
                hidden_category: { eq: false },
              }
            ) {
              edges {
                node {
                  api_id
                  number_of_days
                }
              }
            }
          }
        `}
        render={({ allTourPages }) => {
          const { applyingPromo, promoCodeInserted, promoCodeErrorMessage } = this.state;
          const { page } = this.props;
          const TOUR = FORMAT_GRAPH_DATA(allTourPages).find(tour => tour.api_id === currentBooking.tour_id);

          return (
            <aside className={`c-booking-summary ${booking.updating ? STATES.UPDATING : ''} ${HIDE_REMOVE_BTNS ? STATES.LOCKED : ''} u-card`}>
              {
                currentBooking
                  ? (
                    <>
                      <div className="c-booking-summary__title">
                        <h3 className="c-heading c-heading--h3 c-booking-summary__tour-title">
                          {currentBooking.tour_name}
                        </h3>
                        { TOUR != null ? <h4 className="c-heading c-heading--h5">{TOUR.number_of_days} days</h4> : ''}
                        {showEdit
                          ? (
                            <Link
                              to={`${PAGES.BOOKINGS_ESSENTIALS.PATH}?bookingId=${currentBooking.id}&tour=${currentBooking.tour_slug}`}
                              className="c-booking-summary__edit-btn"
                            >
                              Edit
                            </Link>
                          ) : ''
                      }
                      </div>
                      <SummaryList
                        title="Key Dates"
                        items={KEY_DATES}
                      />
                      {
                        travellers.length > 1
                          ? travellers.map((traveller) => {
                            const travCosts = GET_TRAVELLER_COSTS(traveller, addOns, REMOVE_ADD_ON);
                            if (travCosts.length) {
                              return (
                                <SummaryList
                                  key={traveller.id}
                                  title={`${traveller.first_name} Cost (${CURRENCY})`}
                                  items={travCosts}
                                />
                              );
                            }
                            return null;
                          }) : null
                    }
                      <SummaryList
                        title="Costs (NZD)"
                        items={COSTS}
                      />
                      <div className="c-booking-summary__total">
                        <>
                          {
                            currentBooking.total_discount
                            ? (
                              <>
                                <div className="c-booking-summary__total-item c-booking-summary__sub-total">
                                  <h5 className="c-heading c-heading--h5">Subtotal ({CURRENCY})</h5>
                                  <h5 className="c-heading c-heading--h5">{numberToCurrency(currentBooking.total + currentBooking.total_discount)}</h5>
                                </div>
                                <div className="c-booking-summary__total-item c-booking-summary__total-discount">
                                  <h5 className="c-heading c-heading--h5">Discount ({CURRENCY})</h5>
                                  <h5 className="c-heading c-heading--h5">-{numberToCurrency(currentBooking.total_discount)}</h5>
                                </div>
                              </>
                            ) : null
                          }
                        </>
                        <div className="c-booking-summary__total-item">
                          <h5 className="c-heading c-heading--h5">Your Total ({CURRENCY})</h5>
                          <h5 className="c-heading c-heading--h5">{numberToCurrency(currentBooking.total)}</h5>
                        </div>
                        {
                        !currentBooking.promo_code_valid && !currentBooking.total_discount && page === 'bookings/traveller-details'
                          ? (
                            <>
                              <div className="c-booking-summary__apply-discount">
                                <div>
                                  <Input
                                    id="DiscountCode"
                                    name="DiscountCode"
                                    label="Discount Code ?"
                                    placeholder="Please enter discount code here"
                                    onChange={handleChange}
                                  />
                                  {!applyingPromo && promoCodeInserted && (
                                    !currentBooking.promo_code_valid ? (
                                      <h5 className="c-booking-summary__apply-discount-failed">{promoCodeErrorMessage}</h5>
                                    ) : null
                                  )}
                                  {applyingPromo && (
                                    <div>
                                      <ClipLoader
                                        sizeUnit="px"
                                        size={50}
                                        color="#123abc"
                                      />
                                    </div>
                                  )}
                                  {!applyingPromo && (
                                    <input
                                      type="button"
                                      value="Apply"
                                      className="c-button c-button--border"
                                      onClick={handleClick}
                                    />
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null
                          }
                      </div>
                      {
                      currentBooking.promo_code_valid
                        ? (
                          <div className="c-booking-summary__coupon-message">
                            <h5>
                              Success! Your promo code has been applied.<br />
                              {
                                COUPON_APPLIED
                                  ? `This code expires on ${moment(COUPON_APPLIED.expired_date).format('DD MMM YYYY')}.` : null
                              }
                            </h5>
                          </div>
                        ) : null
                    }
                    </>
                  ) : <h4>Loading...</h4>
              }
            </aside>
          );
        }}
      />
    );
  }
}

BookingSummary.propTypes = {
  booking: PropTypes.any.isRequired,
  showEdit: PropTypes.bool,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  coupons: PropTypes.any.isRequired,
  page: PropTypes.string,
};

BookingSummary.defaultProps = {
  showEdit: false,
  page: 'bookings',
};

export default connect(mapStateToProps)(BookingSummary);
