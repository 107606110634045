import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  AltPanels,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: PAGES.ABOUT.TITLE,
    absolute_slug: PAGES.ABOUT_STORY.PATH,
  },
  {
    name: PAGES.ABOUT_STORY.TITLE,
    absolute_slug: PAGES.ABOUT_STORY.PATH,
  },
];

const AboutStoryPage = () => (
  <Layout page="about/story">
    <Helmet
      title="How Haka Tours Became the Award-Winning NZ Tour Operator | Haka Tours"
      meta={[
        {
          name: 'description', content: `Haka Tours was founded in 2007 when one man decided to reimagine how small group adventure tours were run. Since then,
          there's been no looking back, and now we're regarded as New Zealand’s leading small group adventure operator.`,
        },
        { name: 'og:title', content: 'How Haka Tours Became the Award-Winning NZ Tour Operator | Haka Tours' },
        {
          name: 'og:description', content: `Haka Tours was founded in 2007 when one man decided to reimagine how small group adventure tours were run. Since then,
          there's been no looking back, and now we're regarded as New Zealand’s leading small group adventure operator.`,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-story/` }]}
    />

    <Banner backgroundImage="https://hakatours-staging.imgix.net/000/our-story-banner.jpg?fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">Our Story</h2>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_PURPOSE, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <AltPanels items={[
        {
          title: 'FROM HUMBLE BEGINNINGS',
          content: `<p>Haka Tours was born almost two decades ago from the dreams of one man who wanted to reimagine how small group adventure tours were run. 
          Our very first tour was in 2007 and we’ve been on an extraordinary journey ever since! We’re incredibly proud to be regarded as New Zealand’s leading small group adventure operator and are still delighted to welcome each and every single customer to Aotearoa New Zealand.
          </p>
          <p>As we’ve grown, we’ve stayed true to our core values which remain the key to our success. 
          We are now 100% owned by <a href="https://www.intrepidtravel.com/nz" target="_blank">Intrepid Travel</a> , who embody our same desire to tread lightly on our planet and to create impactful travel experiences for our guests.</p>
          `,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://static-hakatours.imgix.net/our-story-01b.jpg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
        {
          title: 'ADVENTURE, PLUS & WINTER',
          content: `<p>With a natural playground on our doorstep, and to satisfy our customers’ thirst for adventure as much as our own, we added Snow tours in 2008 to support our existing Adventure tours, and later on, Haka Plus tours.
          We are a year-round adventure tour operator and recommend New Zealand to travellers for each and every season!
          </p>
          <p>
            With countless miles under our belts and knowing New Zealand like the back of our hands, we’re true experts at arranging <a href="https://hakatours.com/private-tours" target="_blank">Private Tours</a>
            for couples, families and groups looking for a fully bespoke experience, whether it be self-drive or accompanied by a driver-guide.
          </p>`,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://hakatours-staging.imgix.net/000/our-story-humble.jpg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
        {
          title: 'DEVELOPING TRUE NEW ZEALAND EXPERTS',
          content: `<p>Becoming an expert tour guide is not something that happens overnight, and a huge upside to operating tours year-round is that many of our Tour Managers are able to make guiding a full-time job. 
          Many have stayed with us for years, honing their knowledge and experience. 
          Haka Tours is a family, and we’re passionate about delivering the very best tours – it’s what we live for!
          </p>`,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://hakatours-staging.imgix.net/000/our-story-developing-true-new.jpeg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
        {
          title: 'A NEW ZEALAND SUCCESS STORY!',
          content: `<p>More than a decade on, we couldn’t be prouder of how far we’ve come. 
          As a true New Zealand grass-roots start-up built on just a few thousand dollars seed capital, our aspiration to be a leading tourism operator has definitely become a dream reality. 
          We are stoked to have won a number of awards through the years with most recent accolades being the 2019 Adventure Tourism Awards’ inaugural ‘Cultural Tourism’ award for New Zealand; 
          names as one of the 2019 Deloitte Fast 50 and to be the Supreme & Category winner at the 2019 ExportNZ Awards. 
          Discover more about <a href="/about-us/our-awards/" target="_blank">our Awards</a>.
          </p>`,
          image: {
            width: 652,
            height: 418,
            title: 'Example image title',
            url: 'https://hakatours-staging.imgix.net/000/our-story-success.jpg?w=652&h=418&fit=crop&auto=format',
            align: '',
          },
        },
      ]}
      />
    </section>

    <section className="l-section">
      <div className="l-container l-container--small u-text--center">
        <p>&nbsp;</p>
      </div>
    </section>

    {/* <BlogList */}
    {/* title="The Latest Haka News" */}
    {/* list={[1, 2]} */}
    {/* /> */}

  </Layout>
);

export default AboutStoryPage;
