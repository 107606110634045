import React from 'react';
import {
  AltPanels,
} from 'layouts';

const TourDescription = () => (
  <section className="l-section">
    <div className="l-container l-container--small u-text--center">
      <h2 className="c-heading c-heading--h2">SMALL-GROUP GUIDED NEW ZEALAND TOURS WITH ACCOMMODATION INCLUDED</h2>
    </div>
    <br /><br />
    <div className="l-container u-text--center">
      New Zealand is a land full of scenic wonders, has a unique indigenous culture and is the home of many amazing adventure activities.
      The team at Haka Tours wants to ensure you see the very best of New Zealand, which is why we’ve developed a range of New Zealand travel packages to suit everyone – from sight-seeing visitors to skiers and snowboarders.
    </div>
    <br /><br />
    <AltPanels items={[
      {
        title: 'Adventure tours',
        content: `<p>Our <a href="/new-zealand-tours/" target="_blank">Adventure tours</a> are New Zealand sightseeing tours with a selection of adventure add-on activities which you can book and pay for whilst on tour.
        We’ve included some Māori culture experiences so that you can get a deeper insight into New Zealand
        Accommodation on these tours is in backpacker hostels where you can choose to sleep in a dorm room or get a private room upgrade. About 70% of our adventure guests join the tour solo.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'A group of people with their arms raised in joy stand in front of a New Zealand tour bus with a Mount Ngauruhoe in the distance',
          url: 'https://hakatours-staging.imgix.net/000/tour-images-1.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'Haka Plus tours',
        content: `<p>Our <a href="/haka-plus-tours/" target="_blank">Haka Plus tours</a> are created for travellers who like a little bit more comfort with their travel.
        The group stays in twin-share 3–4 star accommodation (rooms assigned based on gender and age) with the option to upgrade to a private room.
        Similar to the Adventure tours, the itineraries have a range of add-on activities, include some authentic Māori experiences and have a few higher-value inclusions such as sunset boat cruises, wine tastings and more.
        It’s a slightly slower pace with multi-nights at multiple locations. If you prefer not to stay in a dorm-room and like to take a gentler pace, these are the New Zealand tour packages for you.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'A couple in their mid-thirties take a selfie snap while touring Franz Josef Glacier',
          url: 'https://hakatours-staging.imgix.net/000/haka-plus-franz-josef.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
      {
        title: 'Haka Snow tours',
        content: `<p><a href="/new-zealand-snow-tours/" target="_blank">Haka Snow tours</a> package up the best of New Zealand’s skiing and snowboarding.
        Our Snow Safari is based in the South Island and features dorm accommodation with the ability to upgrade to a private room. Our snow tours include all accommodation, bus transport, mountain transfers, lift passes, breakfasts and an expert snow guide to show you the highlights of each mountain.
        You can also add on lessons & gear at time of booking to make things even easier for you.
        Our tours travel from Christchurch to Queenstown (or in reverse) and visit award-winning ski field Mt Hutt, Ohau, Cardrona, Treble Cone, The Remarkables and Coronet Peak.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Three people sitting on their snowboards on top of Mount Ohau gaze out at the view of the lake below and the Southern Alps during a break on their NZ snow tour',
          url: 'https://hakatours-staging.imgix.net/000/HakaSnowSafari.ohauview1_original.jpg?w=652&h=418&fit=crop&auto=format',
          align: '',
        },
      },
    ]}
    />
    <br /><br />
    <blockquote className="l-container u-text--center">
      Your New Zealand sightseeing tour can be action-packed or mellow, but we’ll do all we can to ensure you leave us with great memories, some new friends and a good insight into New Zealand and its people.
    </blockquote>
    <br /><br />
    <div className="u-text--center">
      <a className="c-button c-button--border c-button--small" href="#how-our-tour-work" style={{ backgroundColor: '#e01356', color: '#FFF' }}>How Our Tours Work</a>
    </div>
  </section>
);

export default TourDescription;
