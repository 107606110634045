import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { PAGES, BASE_URL } from 'config';
import { FORMAT_GRAPH_DATA } from 'utilities';
import { Layout } from 'containers';
import { IncludedActivityList, VideoIntro } from 'layouts';
import { Banner, Breadcrumbs } from 'components';

const breadcrumbs = [
  {
    name: PAGES.INCLUDED_ACTIVITIES.TITLE,
    absolute_slug: PAGES.INCLUDED_ACTIVITIES.PATH,
  },
];

const ActivitiesPage = ({ data }) => {
  const activities = FORMAT_GRAPH_DATA(data.allActivityPages);
  const disableVideoPromo = false;

  return (
    <Layout page="included_activities">
      <Helmet
        title="New Zealand Highlights | Adventure Travel NZ | Haka Tours"
        meta={[
          {
            name: 'description', content: 'Adventure travel in New Zealand is highly rated. Tours feature New Zealand highlights & activities for all interests including movies, culture, water & nature.',
          },
          { name: 'og:title', content: 'New Zealand Highlights | Adventure Travel NZ | Haka Tours' },
          {
            name: 'og:description', content: 'Adventure travel in New Zealand is highly rated. Tours feature New Zealand highlights & activities for all interests including movies, culture, water & nature.',
          },
        ]}
        link={[{ rel: 'canonical', href: `${BASE_URL}/included_activities/` }]}
      />

      <Banner backgroundImage="https://hakatours-staging.imgix.net/000/DSCF9335.jpg?fit=crop&auto=format">
        <h1 className="c-heading c-heading--h0">Included activities</h1>
      </Banner>

      <section className="l-activity-category-list">
        <div className="l-container">
          <Breadcrumbs list={breadcrumbs} />

          <div className="l-activity-category-list__header">
            <p>
              We include a handful of inclusions to give you a flavour of our rich Māori culture such as Pounamu carving, as well as a few must-do activities such as a sunset cruise to allow you to really get to know a destination.
            </p>
          </div>
        </div>
      </section>

      <IncludedActivityList
        list={activities}
      />

      <VideoIntro
        title="How our tours work"
        videoUrl="https://www.youtube.com/watch?v=ITSEGjWanWY&feature=youtu.be"
        hideReviews
        disableVideoPromo={disableVideoPromo}
      >
        <h4 className="c-heading c-heading--h4">Step 1: SELECT YOUR BASE TOUR</h4>
        <p>
          Every tour includes: accommodation, transport, tour guide, breakfast and a few key activities!
        </p>
        <h4 className="c-heading c-heading--h4">Step 2: CUSTOMISE YOUR TOUR</h4>
        <p>
          Customise your trip by upgrading your accommodation or adding pre or post tour accommodation.
          You can view the activities available in New Zealand and decide which ones you want to do - just add these to your ‘favourites’ by clicking the heart button.
          All activities can be booked on the road by your Tour Guide.
        </p>
        <h4 className="c-heading c-heading--h4">Step 3: Book and manage your tour online</h4>
        <p>
          Secure your seat for a $99 deposit ($400 for departures from June 2025), then pay it off over time – the balance is due 60 days prior to departure (56 days for departures from June 2025).
        </p>
        <p>
          It’s that easy! If you have any questions at all, then please <a href="/contact-us/" target="_blank">chat with us</a>.
        </p>
      </VideoIntro>
    </Layout>
  );
};

ActivitiesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allActivityCategories {
      edges {
        node {
          id
          api_id
          name
          short_description
          absolute_slug
          picture_id
        }
      }
    }

    allActivityPages(
      filter: {
        is_included_activity: { eq: true },
        guide_app_only: { ne: true },
      }
    )  {
      edges {
        node {
          id
          api_id
          name
          location
          destination_name
          description_markdown
          absolute_slug
          addon_category_ids
          sliding_first_picture_id
          tour_ids
          options {
            price
            hidden
            archived
            seasonal_pricings {
              id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }

    allActivityImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }
  }
`;

export default ActivitiesPage;
